export default class Environment {
    static ENV : string = process.env.REACT_APP_ENV || 'REACT_APP_ENV_PLACEHOLDER'
    static HORUS_BACKEND_URL: string = 'REACT_APP_HORUS_BACKEND_URL_PLACEHOLDER'
    static ASSET_REPOSITORY_LOCATION: string = 'REACT_APP_ASSET_REPOSITORY_LOCATION_PLACEHOLDER'
    static ASSETS_MAIN_FILE : string = 'css/index.css'
    static ROCKET_URL:string = 'ROCKET_URL_ENV_PLACEHOLDER'
}

/*
export default class Environment {
    static ENV : string = process.env.REACT_APP_ENV || 'REACT_APP_ENV_PLACEHOLDER'
    static HORUS_BACKEND_URL: string = 'REACT_APP_HORUS_BACKEND_URL_PLACEHOLDER'
    static ASSET_REPOSITORY_LOCATION: string = 'https://static.eterland.etermax.com/staging'
    static ASSETS_MAIN_FILE : string = 'css/index.css'
    static ROCKET_URL:string = 'https://rocket.innovation-staging.etermax.com'
}
 */
