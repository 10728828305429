import React from 'react'
import { GameScreen } from '../composable/GameScreen'
import { EndcardScreenProps } from '../props/EndcardScreenProps'
import CustomButton from '../CustomButton'

export const EndcardScreen = (props:EndcardScreenProps) =>
    <GameScreen>
        <link href={props.style} rel="stylesheet"/>
        <div className="logo" id="client-logo"/>

        <div className="container endcard-container">
            <div className="ribbon-title">¡Tenés un 10% off!</div>
            <div className="reward-circle-background">
                <div className="line description" id="endcard-subtitle">
                    <div>
                        En tu próxima compra online.<br/>
                        {/* {props.configuration.subtitle} */}
                    </div>
                </div>
                <div className="line description" id="endcard-subtitle-2">
                    <div>
                        *Te enviaremos por mail cómo<br/>
                        canjear tu cupón.
                        {/* {props.configuration.subtitle} */}
                    </div>
                </div>
                <div className="endcard-image">
                    <CustomButton
                        className="wide-button endcard-button"
                        text="Ir al sitio"// {props.configuration.buttonText}
                        onClick={() => {
                            props.back()
                            open(props.configuration.endcardUrl)
                        }}
                    />
                </div>
            </div>
            <div className="sub-line sub-title brandon">
                ¡Muchas gracias<br/>
                por esperar!
                {/* {props.configuration.message} */}
            </div>
        </div>
    </GameScreen>
